<template>
  <div>
    <v-speed-dial
      bottom
      direction="top"
      fixed
      right
      style="z-index: 20; right: 0px;"
      transition="slide-y-reverse-transition"
      v-model="fab"
      :class="{'mobile-dial': isMobileDevice, isIOS13Device}"
    >
      <template
        v-slot:activator
      >
        <div
          style="background: rgba(255, 255, 255, 0.8); height: 48px; border-radius: 8px; backdrop-filter: blur(4px); cursor: pointer;"
          class="d-flex align-center pl-2 pr-2 mx-2"
        >
          <v-icon
            size="32"
            style="cursor: pointer;"
            v-model="fab"
          >
            mdi-plus
          </v-icon>
        </div>
      </template>

      <v-btn
        :color="type.color"
        :key="`item-create-${type.name}`"
        @click="$emit('createAction', type)"
        dark
        fab
        small
        v-for="type in $store.getters.types"
      >
        <v-icon
          size="20"
        >
          {{ type.icon }}
        </v-icon>
        <div class="fab-text-custom">{{ type.title }}</div>
      </v-btn>
    </v-speed-dial>
    <v-overlay
      class="speed-dial-overlay"
      color="white"
      opacity="0.9"
      v-if="$vuetify.breakpoint.xs"
      v-model="fab"
    />
  </div>
</template>
<script>
import { isIOS13 } from 'mobile-device-detect'

export default {
  props: {
    isMobileDevice: {
      type: Boolean,
      required: true
    }
  },
  name: 'SpeedDialWidget',
  data: () => ({
    fab: false,
  }),
  computed: {
    isIOS13Device() {
      return isIOS13
    }
  }
}
</script>